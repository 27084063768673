/**
 * This file contains configuration for `gatsby-plugin-prismic-previews` to
 * support preview sessions from Prismic with drafts and unpublished documents.
 *
 * @see https://prismic.io/docs/technologies/previews-gatsby
 */

import { componentResolverFromMap } from 'gatsby-plugin-prismic-previews'

import { prismicRepo } from '../../prismic-configuration'
import { linkResolver } from './linkResolver'

import PageTemplate from '../templates/page'
import HomepageTemplate from '../templates/homepage'
import FaqTemplate from '../templates/faq'
import ContactTemplate from '../templates/contact'
import TeacherChecklistTemplate from '../templates/teacher_checklist'
import TeacherTrainingTemplate from '../templates/teacher_training'
import ModuleOverviewTemplate from '../templates/module_overview'
import ModuleLessonTemplate from '../templates/module_lesson'
import JudgesTemplate from '../templates/judges'
import WinnersTemplate from '../templates/winners'
/**
 * Prismic preview configuration for each repository in your app. This set of
 * configuration objects will be used with the `PrismicPreviewProvider`
 * higher order component.
 *
 * If your app needs to support multiple Prismic repositories, add each of
 * their own configuration objects here as additional elements.
 *
 */
export const repositoryConfigs = [
  {
    repositoryName: prismicRepo,
    linkResolver,
    componentResolver: componentResolverFromMap({
      page: PageTemplate,
      homepage: HomepageTemplate,
      faq: FaqTemplate,
      contact: ContactTemplate,
      teacher_checklist: TeacherChecklistTemplate,
      teacher_training: TeacherTrainingTemplate,
      module_overview: ModuleOverviewTemplate,
      module_lesson: ModuleLessonTemplate,
      judges: JudgesTemplate,
      winners: WinnersTemplate
    }),
  },
]
